import React from "react";
import ProductSelectStyles from "./ProductSelect.styles";
import {Grid, Tooltip, Typography} from "@material-ui/core";
import {Image} from "@material-ui/icons";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

interface props{
    imageUrl?: string;
    productName: string;
    onSelect: () => void;
    buttonDescription: string;
    caption?: string;
    subCaption?: string;
    isSelected: boolean;
    customButtonClass?: string;
}

const ProductSelect: React.FC<props> = ({
    imageUrl,
    productName,
    caption,
    buttonDescription,
    subCaption,
    onSelect,
    isSelected,
    customButtonClass
}:props) => {
    
    const classes = ProductSelectStyles();
    
    return(
        <div className={classes.root}>
        <Grid container>
            <Grid className={classes.grid} item xs={4}>
                <div className={classes.imageContainer}>
                    {imageUrl ?
                        <img className={classes.image}  src={`${imageUrl}`} alt={'product image'}/>
                        :
                        <Image className={classes.image}/>
                    }
                    {isSelected && <CheckCircleRoundedIcon className={classes.checkIcon} />}
                </div>                             
            </Grid>
            <Grid className={classes.grid} xs={8}>
                <div className={classes.infoContainer}>
                    <Tooltip title={productName}>
                        <Typography className={classes.nameText}>{productName}</Typography>
                    </Tooltip>
                    {caption && <Typography className={classes.captionText}>{caption}</Typography>}
                    <div className={`${customButtonClass} ${classes.button}`} onClick={onSelect}>
                        <Typography className={classes.buttonText}>{buttonDescription}</Typography>
                    </div>
                    {subCaption && <Typography className={classes.subCaptionText}>{subCaption}</Typography>}
                </div>
            </Grid>
        </Grid>    
    </div>)
}

export default ProductSelect;