import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


const ProductSelectStyles = makeStyles((theme: Theme) =>
{
    return createStyles({
        root:{
            width: '100%',
            height: '100px',
            padding: `0 ${theme.spacing(1)}px`,
            marginBottom: theme.spacing(1),
        },
        infoContainer: {
            marginLeft: '10px',
            display: 'block',
            flexDirection: 'column',
            maxHeight: '90px'
        },
        button:{

            width: 'fit-content',
            padding: `4px ${theme.spacing(1)}px`,
            
            borderRadius: '10px',
            background: 'white',
            border: '2px solid #EAECF0',
            //boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            
            display: 'inline-flex',
            float: 'inline-end',
            justifySelf: 'center',
            alignSelf: 'end',
            
            '&:hover': {
                filter: 'brightness(0.9)',
                background: 'white',
                color: 'black'
            },
            cursor: 'pointer',
            position: 'relative',
            zIndex: 55
        },
        grid: {
            display: 'grid'
        },
        imageContainer: {
            position: "relative",
            display: 'grid'
        },
        image:{
            margin: '0',
            alignSelf: 'center',
            justifySelf: 'center',

            padding: '0px !important',
            maxWidth: '95%',
            maxHeight: '86px',
            borderRadius: '10%',
            color: 'grey',
            pointerEvents: 'none',
            touchAction: 'none',
        },
        checkIcon: {

            position: 'absolute',
            right: '5%',
            top: '5%',
            
            fontSize: '1.5rem', //.5
            color: theme.palette.checkIcons.default,
            background: 'white',
            borderRadius: '100%'
        },
        nameText:{
            fontWeight: 'bold',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            // Fallbacks
            maxHeight: '55%',
            textOverflow: 'ellipsis',
        },
        captionText:{
            fontSize: '0.8rem'
        },
        buttonText:{},
        subCaptionText:{
            position: 'relative',
            bottom: '0',
            fontSize: '0.8rem',
            color: theme.palette.checkIcons.default,
        },
    })
})

export default ProductSelectStyles;